<template>
    <div class="container pt-1 scrlt-y" v-bind:style="'height:'+  (screenHeight-50) +'px;'">
            <model-list-select
                :list="datsupp"
                class="form-control mb-3"
                option-value="ts_id"
                option-text="ts_name"
                @input="changeSup"
                v-model="supply.ts_id"
                placeholder="Pilih Supplier"
            ></model-list-select>

        <div class="row mb-3 overflow-auto h-25">
            <div class="col-3 border">
                bulan sekarang
                <ul>
                    <li v-for="(item, index) in recn" :key="index">
                      <p class="fw-bold">Group: {{ item.tp_group }}</p>
                      <p>Kubikasi: {{ item.kubikasi }} m³</p>
                      <p>Qty: {{ item.qty }}</p>
                    </li>
                  </ul>
            </div>
            <div class="col-3 border">
                bulan kemarin
                <ul>
                    <li v-for="(item, index) in recm" :key="index">
                      <p class="fw-bold">Group: {{ item.tp_group }}</p>
                      <p>Kubikasi: {{ item.kubikasi }} m³</p>
                      <p>Qty: {{ item.qty }}</p>
                    </li>
                  </ul>
            </div>
            <div class="col-3 border">
                tahun ini
                <ul>
                    <li v-for="(item, index) in recy" :key="index">
                      <p class="fw-bold">Group: {{ item.tp_group }}</p>
                      <p>Kubikasi: {{ item.kubikasi }} m³</p>
                      <p>Qty: {{ item.qty }}</p>
                    </li>
                  </ul>
            </div>
            <div class="col-3 border">
                tahun kemarin
                <ul>
                    <li v-for="(item, index) in recl" :key="index">
                      <p class="fw-bold">Group: {{ item.tp_group }}</p>
                      <p>Kubikasi: {{ item.kubikasi }} m³</p>
                      <p>Qty: {{ item.qty }}</p>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="col-md-12 mb-3">
            <div class="card p-3">
                <div class="card-header bg-transparent mb-3 h9 pt-0 fw-bold">
                    Table Customer
                </div>
                <table class="table table-hover table-responsive" id="ratio" style="cursor:pointer">
                    <thead>
                        <tr class="table-head">
                            <th class="">Tanggal</th>
                            <th class="">Nama Item</th>
                            <th class="">Jenis Item</th>
                            <th class="">Qty</th>
                            <th class="">Kubikasi</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "../chart/PieChart.js";
import moment from "moment";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5.min.js";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import "datatables.net-buttons/js/buttons.html5.min.js";

import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'D-Supplier',
    components: {
        PieChart,ModelListSelect
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            year : moment(new Date()).format('YYYY'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            yearSub : moment().subtract(1, 'year').format('YYYY'),
            card : {
                custon : 0,
                custoff : 0,
            },

            datsupp : [],
            supply : {
                ts_id : null,
            },
            recsratiocust : [],
            recn : [],
            recm : [],
            recy : [],
            recl : [],

        }
    },
    methods : {
        changeSup(event){
            this.LoadData();
            console.log('Selected supplier ID:', this.supply.ts_id);
        },
        async LoadData() {
            try {
                document.getElementById('idLoading').style.display = 'block';

                const [getsumR, recnR, recmR, recyR, reclR] = await Promise.all([
                    axios.post("dashboard/ApiSupp.php", {
                        pfunction: "getsum",
                        supp: this.supply.ts_id
                    }),
                    axios.post("dashboard/ApiSupp.php", {
                        pfunction: 'getn',
                        supp: this.supply.ts_id
                    }),
                    axios.post("dashboard/ApiSupp.php", {
                        pfunction: 'getm',
                        supp: this.supply.ts_id
                    }),
                    axios.post("dashboard/ApiSupp.php", {
                        pfunction: 'gety',
                        supp: this.supply.ts_id
                    }),
                    axios.post("dashboard/ApiSupp.php", {
                        pfunction: 'getl',
                        supp: this.supply.ts_id
                    })
                ]);

                document.getElementById('idLoading').style.display = 'none';
                this.recsratiocust = getsumR.data.rec;
                this.recn = recnR.data.recs;
                this.recm = recmR.data.reca;
                this.recy = recyR.data.recb;
                this.recl = reclR.data.recc;

                $('#ratio').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: false,
                    deferRender: true,
                    autoWidth: false,
                    data: this.recsratiocust,
                    columns: [
                        {"data": "tp_date"},
                        {"data": "i_name"},
                        {"data": "tp_group"},
                        {"data": "tp_qty"},
                        {
                            data: "tp_qty2",
                            render: function(data, type, row) {
                                if (type === 'display') {
                                    return row.tp_qty2 + ' m³';
                                }
                                return row.tp_qty2;
                            }
                        }
                    ],
                    order: [[0, "desc"]],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                        "<'col-md-6'B>" + "<'row'<'col-md-12 h9 mb-3'tr>>" + "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        {
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',
                        }
                    ],
                });
            } catch (error) {
                console.error("Error:", error);
                // Tampilkan pesan kesalahan kepada pengguna jika permintaan gagal
            }
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },

        setInit() {
            let self = this;
            axios.post("dashboard/ApiSupp.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.datsupp = response.data.rec;
            });
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777215).toString(16);
            return code;
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
